import { ArrowBack } from '@mui/icons-material';
import {
	AppBar,
	Avatar,
	Box,
	createTheme,
	IconButton,
	Tab,
	Tabs,
	ThemeProvider,
	Toolbar,
	Typography,
} from '@mui/material';
import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Footer from '../components/Footer';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const theme = createTheme({
	palette: {
		primary: {
			main: '#F5F1E8',
		},
		secondary: {
			main: '#F49C34',
		},
	},
});

const Gallery = () => {
	const [index, setIndex] = React.useState(0);

	const itemData = [
		{
			img: 'static/pic5.jpeg',
			title: 'Hats',
			rows: 4,
			cols: 4,
		},
		{
			img: 'static/pic3.jpeg',
			title: 'Breakfast',
			rows: 2,
			cols: 2,
		},
		{
			img: 'static/pic2.jpeg',
			title: 'Burger',
			rows: 2,
			cols: 2,
		},
		{
			img: 'static/pic1.jpeg',
			title: 'Camera',
			// rows: 2,
			cols: 4,
		},
		{
			img: 'static/pic4.jpeg',
			title: 'Coffee',
			// rows: 2,
			cols: 4,
		},
		{
			img: 'static/guy1.jpeg',
			title: 'Breakfast',
			rows: 4,
			cols: 4,
		},
		{
			img: 'static/untitled-101.jpg',
			title: 'Burger',
			rows: 2,
			cols: 2,
		},
		{
			img: 'choir4.jpeg',
			title: 'Camera',
			rows: 2,
			cols: 2,
		},
		{
			img: 'static/untitled-103.jpg',
			title: 'Coffee',
			cols: 4,
		},
		{
			img: 'static/untitled-104.jpg',
			title: 'Hats',
			cols: 4,
		},
		{
			img: 'static/untitled-105.jpg',
			title: 'Honey',
			author: '@arwinneil',
			rows: 4,
			cols: 4,
		},
		{
			img: 'static/untitled-2.jpg',
			title: 'Basketball',
			rows: 2,
			cols: 2,
		},
		{
			img: 'static/untitled-107.jpg',
			title: 'Fern',
			rows: 2,
			cols: 2,
		},
		{
			img: 'static/untitled-108.jpg',
			title: 'Mushrooms',
			rows: 4,
			cols: 4,
		},
		{
			img: 'static/untitled-109.jpg',
			title: 'Tomato basil',
			rows: 2,
			cols: 2,
		},
		{
			img: 'static/untitled-110.jpg',
			title: 'Sea star',
			rows: 2,
			cols: 2,
		},
		{
			img: 'static/untitled-111.jpg',
			title: 'Bike',
			cols: 4,
		},
		{
			img: 'static/untitled-112.jpg',
			title: 'Bike',
			cols: 4,
		},
		{
			img: 'static/untitled-113.jpg',
			title: 'Breakfast',
			rows: 4,
			cols: 4,
		},
		{
			img: 'static/untitled-114.jpg',
			title: 'Burger',
			rows: 2,
			cols: 2,
		},
		{
			img: 'static/untitled-115.jpg',
			title: 'Camera',
			rows: 2,
			cols: 2,
		},
		{
			img: 'static/untitled-130.jpg',
			title: 'Coffee',
			cols: 4,
		},
		{
			img: 'static/untitled-117.jpg',
			title: 'Hats',
			cols: 4,
		},
		{
			img: 'static/untitled-118.jpg',
			title: 'Honey',
			author: '@arwinneil',
			rows: 4,
			cols: 4,
		},
		{
			img: 'static/untitled-119.jpg',
			title: 'Basketball',
			rows: 2,
			cols: 2,
		},
		{
			img: 'static/untitled-120.jpg',
			title: 'Fern',
			rows: 2,
			cols: 2,
		},
		{
			img: 'static/untitled-121.jpg',
			title: 'Mushrooms',
			rows: 4,
			cols: 4,
		},
		{
			img: 'static/untitled-122.jpg',
			title: 'Tomato basil',
			rows: 2,
			cols: 2,
		},
		{
			img: 'static/untitled-123.jpg',
			title: 'Sea star',
			rows: 2,
			cols: 2,
		},
		{
			img: 'static/untitled-124.jpg',
			title: 'Bike',
			cols: 4,
		},
		{
			img: 'static/untitled-131.jpg',
			title: 'Bike',
			cols: 4,
		},
		{
			img: 'choir2.jpeg',
			title: 'Bike',
			cols: 4,
			rows: 4,
		},
		{
			img: 'choir3.jpeg',
			title: 'Bike',
			cols: 4,
			rows: 2,
		},
		{
			img: 'static/choirPic1.jpeg',
			title: 'Bike',
			cols: 4,
			rows: 2,
		},
	];
	const liveData = [
		{
			img: 'live/pic1.jpeg',
			title: 'Hats',
			rows: 4,
			cols: 4,
		},
		{
			img: 'live/pic4.jpeg',
			title: 'Breakfast',
			rows: 2,
			cols: 2,
		},
		{
			img: 'live/pic2.jpeg',
			title: 'Burger',
			rows: 2,
			cols: 2,
		},
		{
			img: 'live/pic5.jpeg',
			title: 'Camera',
			// rows: 2,
			cols: 4,
		},
		{
			img: 'live/pic6.jpeg',
			title: 'Coffee',
			// rows: 2,
			cols: 4,
		},
		{
			img: 'live/untitled-100.jpg',
			title: 'Breakfast',
			rows: 4,
			cols: 4,
		},
		{
			img: 'live/untitled-126.jpg',
			title: 'Burger',
			rows: 2,
			cols: 2,
		},
		{
			img: 'live/untitled-102.jpg',
			title: 'Camera',
			rows: 2,
			cols: 2,
		},
		{
			img: 'live/untitled-103.jpg',
			title: 'Coffee',
			cols: 4,
		},
		{
			img: 'live/untitled-130.jpg',
			title: 'Hats',
			cols: 4,
		},
		{
			img: 'live/choirPic1.jpeg',
			title: 'Tomato basil',
			rows: 2,
			cols: 2,
		},
		{
			img: 'live/choirPic2.jpeg',
			title: 'Sea star',
			rows: 2,
			cols: 2,
		},
		{
			img: 'live/choirPic3.jpeg',
			title: 'Bike',
			cols: 4,
		},
		{
			img: 'live/choirPic4.jpeg',
			title: 'Bike',
			cols: 4,
		},
		{
			img: 'live/untitled-105.jpg',
			title: 'Honey',
			author: '@arwinneil',
			rows: 4,
			cols: 4,
		},
		{
			img: 'live/untitled-106.jpg',
			title: 'Basketball',
			rows: 2,
			cols: 2,
		},
		{
			img: 'live/untitled-107.jpg',
			title: 'Fern',
			rows: 2,
			cols: 2,
		},
		{
			img: 'live/untitled-108.jpg',
			title: 'Mushrooms',
			rows: 4,
			cols: 4,
		},
		{
			img: 'live/untitled-109.jpg',
			title: 'Tomato basil',
			rows: 2,
			cols: 2,
		},
		{
			img: 'live/untitled-110.jpg',
			title: 'Sea star',
			rows: 2,
			cols: 2,
		},
		{
			img: 'live/untitled-133.jpg',
			title: 'Bike',
			cols: 4,
		},
		{
			img: 'live/untitled-134.jpg',
			title: 'Bike',
			cols: 4,
		},
		{
			img: 'live/untitled-113.jpg',
			title: 'Breakfast',
			rows: 4,
			cols: 4,
		},
		{
			img: 'live/untitled-114.jpg',
			title: 'Burger',
			rows: 2,
			cols: 2,
		},
		{
			img: 'live/untitled-115.jpg',
			title: 'Camera',
			rows: 2,
			cols: 2,
		},
		{
			img: 'live/untitled-116.jpg',
			title: 'Coffee',
			cols: 4,
		},
		{
			img: 'live/untitled-117.jpg',
			title: 'Hats',
			cols: 4,
		},
		{
			img: 'live/untitled-118.jpg',
			title: 'Honey',
			author: '@arwinneil',
			rows: 4,
			cols: 4,
		},
		{
			img: 'live/untitled-119.jpg',
			title: 'Basketball',
			rows: 2,
			cols: 2,
		},
		{
			img: 'live/untitled-120.jpg',
			title: 'Fern',
			rows: 2,
			cols: 2,
		},
		{
			img: 'live/untitled-121.jpg',
			title: 'Mushrooms',
			rows: 4,
			cols: 4,
		},
		{
			img: 'live/untitled-122.jpg',
			title: 'Tomato basil',
			rows: 2,
			cols: 2,
		},
		{
			img: 'live/untitled-123.jpg',
			title: 'Sea star',
			rows: 2,
			cols: 2,
		},
		{
			img: 'live/untitled-136.jpg',
			title: 'Bike',
			cols: 4,
		},
		{
			img: 'live/untitled-137.jpg',
			title: 'Bike',
			cols: 4,
		},
	];
	const bscenesData = [
		{
			img: 'bts/bus1.jpeg',
			title: 'Breakfast',
			rows: 4,
			cols: 4,
		},
		{
			img: 'bts/stage1.jpeg',
			title: 'Burger',
			cols: 4,
		},
		{
			img: 'bts/bus2.jpeg',
			title: 'Camera',
			rows: 2,
			cols: 4,
		},
		{
			img: 'bts/stage3.jpeg',
			title: 'Coffee',
			cols: 4,
		},
		{
			img: 'bts/untitled-181.jpg',
			title: 'Breakfast',
			rows: 4,
			cols: 4,
		},
		{
			img: 'bts/untitled-182.jpg',
			title: 'Burger',
			rows: 2,
			cols: 2,
		},
		{
			img: 'bts/untitled-183.jpg',
			title: 'Camera',
			rows: 2,
			cols: 2,
		},
		{
			img: 'bts/untitled-184.jpg',
			title: 'Coffee',
			cols: 4,
		},
		{
			img: 'bts/untitled-207.jpg',
			title: 'Hats',
			cols: 4,
		},
		{
			img: 'bts/untitled-186.jpg',
			title: 'Honey',
			author: '@arwinneil',
			rows: 4,
			cols: 4,
		},
		{
			img: 'bts/untitled-187.jpg',
			title: 'Basketball',
			rows: 2,
			cols: 2,
		},
		{
			img: 'bts/untitled-188.jpg',
			title: 'Fern',
			rows: 2,
			cols: 2,
		},
		{
			img: 'bts/untitled-189.jpg',
			title: 'Mushrooms',
			rows: 4,
			cols: 4,
		},
		{
			img: 'bts/untitled-190.jpg',
			title: 'Tomato basil',
			rows: 2,
			cols: 2,
		},
		{
			img: 'bts/untitled-191.jpg',
			title: 'Sea star',
			rows: 2,
			cols: 2,
		},
		{
			img: 'bts/untitled-192.jpg',
			title: 'Bike',
			cols: 4,
		},
		{
			img: 'bts/untitled-193.jpg',
			title: 'Bike',
			cols: 4,
		},
		{
			img: 'bts/untitled-194.jpg',
			title: 'Breakfast',
			rows: 4,
			cols: 4,
		},
		{
			img: 'bts/untitled-195.jpg',
			title: 'Burger',
			rows: 2,
			cols: 2,
		},
		{
			img: 'bts/untitled-196.jpg',
			title: 'Camera',
			rows: 2,
			cols: 2,
		},
		{
			img: 'bts/pic1.jpeg',
			title: 'Coffee',
			cols: 4,
		},
		{
			img: 'bts/untitled-208.jpg',
			title: 'Hats',
			cols: 4,
		},
		{
			img: 'bts/untitled-199.jpg',
			title: 'Honey',
			author: '@arwinneil',
			rows: 4,
			cols: 4,
		},
		{
			img: 'bts/untitled-200.jpg',
			title: 'Basketball',
			rows: 2,
			cols: 2,
		},
		{
			img: 'bts/untitled-201.jpg',
			title: 'Fern',
			rows: 2,
			cols: 2,
		},
		{
			img: 'bts/untitled-202.jpg',
			title: 'Mushrooms',
			rows: 4,
			cols: 4,
		},
		{
			img: 'bts/untitled-203.jpg',
			title: 'Tomato basil',
			rows: 2,
			cols: 2,
		},
		{
			img: 'bts/untitled-209.jpg',
			title: 'Sea star',
			rows: 2,
			cols: 2,
		},
		{
			img: 'bts/untitled-205.jpg',
			title: 'Bike',
			cols: 4,
		},
		{
			img: 'bts/untitled-206.jpg',
			title: 'Bike',
			cols: 4,
		},
	];
	const awards = [
		{
			img: 'static/pic7.jpeg',
			title: 'Hats',
			rows: 4,
			cols: 4,
		},
		{
			img: 'static/pic6.jpeg',
			title: 'Breakfast',
			rows: 2,
			cols: 2,
		},
		{
			img: 'static/pic8.jpeg',
			title: 'Burger',
			rows: 2,
			cols: 2,
		},
		{
			img: 'static/pic9.jpeg',
			title: 'Camera',
			// rows: 2,
			cols: 4,
		},
		{
			img: 'static/pic10.jpeg',
			title: 'Coffee',
			// rows: 2,
			cols: 4,
		},
	];

	const handleChange = (event, index) => {
		setIndex(index);
	};

	function srcset(image, size, rows = 1, cols = 1) {
		return {
			src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
			srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
		};
	}

	return (
		<ThemeProvider theme={theme}>
			<Helmet>
				<link rel='icon' href='rising_sun3.ico' />
			</Helmet>
			<Box>
				<AppBar position='relative' color='transparent' sx={{ zIndex: 1, boxShadow: 0 }}>
					<Toolbar>
						<Link to='/'>
							<IconButton
								size='large'
								edge='start'
								aria-label='home'
								// href='/'
								sx={{ mr: 2, color: '#F49C34' }}
							>
								<ArrowBack />
							</IconButton>
						</Link>
						<Link to='/'>
							<Avatar
								src='rising_sun.png'
								alt='Rising Sun Foundation Logo'
								variant='rounded'
								sx={{
									width: '200px',
									height: '100%',
								}}
							/>
						</Link>
					</Toolbar>
				</AppBar>
				<Box
					display='flex'
					justifyContent='center'
					alignItems='center'
					width='100%'
					marginTop='20px'
				>
					<Typography
						variant='subtitle1'
						sx={{
							textTransform: 'uppercase',
							fontWeight: 800,
							fontSize: '20pt',
							color: 'rgba(0, 0, 0, 0.8)',
						}}
					>
						Our Gallery
					</Typography>
				</Box>
			</Box>
			<Box sx={{ width: '100%', m: '20px 0' }}>
				<Tabs
					value={index}
					onChange={handleChange}
					textColor='primary'
					indicatorColor='secondary'
					aria-label='Gallery categories'
					variant='scrollable'
					scrollButtons
					allowScrollButtonsMobile
					sx={{
						display: { md: 'none' },
					}}
				>
					<Tab label='Rising Sun Launch' />
					<Tab label='Live Recordings' />
					<Tab label='Behind The Scenes' />
					<Tab label='Ingoma Awards' />
				</Tabs>

				<Tabs
					value={index}
					onChange={handleChange}
					textColor='primary'
					indicatorColor='secondary'
					aria-label='Gallery categories'
					centered
					TabIndicatorProps={{
						style: { color: 'rgba(245,241,232, 1)' },
					}}
					sx={{
						'display': { xs: 'none', md: 'initial' },
						'&.Mui-selected': { color: 'rgba(245,241,232, 1)' },
					}}
				>
					<Tab label='Rising Sun Launch' />
					<Tab label='Live Recordings' />
					<Tab label='Behind The Scenes' />
					<Tab label='Ingoma Awards' />
				</Tabs>
				<Tabpanel value={index} index={0}>
					<ImageList
						sx={{ width: 1000 }}
						variant='quilted'
						cols={8}
						rowHeight={242}
						key={index}
					>
						{itemData.map((item) => (
							<ImageListItem
								key={item.img}
								cols={item.cols || 1}
								rows={item.rows || 1}
							>
								<img
									{...srcset(item.img, 242, item.rows, item.cols)}
									alt={item.title}
									loading='lazy'
								/>
							</ImageListItem>
						))}
					</ImageList>
				</Tabpanel>
				<Tabpanel value={index} index={1}>
					<ImageList
						sx={{ width: 1000 }}
						variant='quilted'
						cols={8}
						rowHeight={242}
						key={index}
					>
						{liveData.map((item) => (
							<ImageListItem
								key={item.img}
								cols={item.cols || 1}
								rows={item.rows || 1}
							>
								<img
									{...srcset(item.img, 242, item.rows, item.cols)}
									alt={item.title}
									loading='lazy'
								/>
							</ImageListItem>
						))}
					</ImageList>
				</Tabpanel>
				<Tabpanel value={index} index={2}>
					<ImageList
						sx={{ width: 1000 }}
						variant='quilted'
						cols={8}
						rowHeight={242}
						key={index}
					>
						{bscenesData.map((item) => (
							<ImageListItem
								key={item.img}
								cols={item.cols || 1}
								rows={item.rows || 1}
							>
								<img
									{...srcset(item.img, 242, item.rows, item.cols)}
									alt={item.title}
									loading='lazy'
								/>
							</ImageListItem>
						))}
					</ImageList>
				</Tabpanel>
				<Tabpanel value={index} index={3}>
					<ImageList
						sx={{ width: 1000 }}
						variant='quilted'
						cols={8}
						rowHeight={242}
						key={index}
					>
						{awards.map((item) => (
							<ImageListItem
								key={item.img}
								cols={item.cols || 1}
								rows={item.rows || 1}
							>
								<img
									{...srcset(item.img, 242, item.rows, item.cols)}
									alt={item.title}
									loading='lazy'
								/>
							</ImageListItem>
						))}
					</ImageList>
				</Tabpanel>
				<Footer />
			</Box>
		</ThemeProvider>
	);
};

const Tabpanel = ({ children, value, index }) => {
	return (
		<Box width={'100%'} hidden={value !== index}>
			{value === index && (
				<Box display={'flex'} justifyContent='center'>
					{children}
				</Box>
			)}
		</Box>
	);
};

export default Gallery;
