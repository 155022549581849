import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	body: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		padding: 0,
		margin: 0,
		backgroundColor: '#EEAB3C',
		minHeight: '100vh',
	},
	hero: {
		// marginTop: 100,
		width: '100%',
		height: 500,
		border: '1px solid black',
		backgroundImage: "url('static/untitled-30.png')",
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		position: 'absolute',
		left: 0,
		top: 0,
		zIndex: 0,
	},
	overlay: {
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.4)',
		zIndex: 1,
		color: '#F5F1E8',
		display: 'flex',
		flexDirection: 'column',
		// alignItems: 'flex-end',
		justifyContent: 'flex-end',
	},
	heroText: {
		display: 'flex',
		height: '50%',
		position: 'relative',
		// width: '50%',
		flexDirection: 'column',
		// justifyContent: 'space-around',
	},
});

export default useStyles;
