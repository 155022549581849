import {
	Box,
	createTheme,
	Grid,
	List,
	ListItem,
	ListItemText,
	ThemeProvider,
	Typography,
} from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import useStyles from '../styles/about_styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#F5F1E8',
		},
		secondary: {
			main: '#F49C34',
		},
	},
});

const About = () => {
	const about_classes = useStyles();
	const [open, setOpen] = React.useState(false);

	return (
		<ThemeProvider theme={theme}>
			<Helmet>
				<link rel='icon' href='rising_sun3.ico' />
			</Helmet>
			<Box className={about_classes.body}>
				<Navbar open={open} setOpen={setOpen} />
				<Sidebar open={open} setOpen={setOpen} />
				<Box className={about_classes.hero}>
					<Box
						className={about_classes.overlay}
						sx={{
							alignItems: { lg: 'flex-start' },
							justifyContent: 'flex-end',
						}}
					></Box>
				</Box>
				<Box
					className={about_classes.heroText}
					sx={{
						display: { xs: 'flex' },
						top: 400,
						justifyContent: 'space-around',
						p: 5,
						color: '#F5F1E8',
					}}
				>
					<Typography
						variant='subtitle1'
						fontSize={22}
						textAlign={'center'}
						sx={{ fontWeight: 800 }}
					>
						ABOUT RISING SUN FOUNDATION
					</Typography>
					<Typography variant='subtitle1' fontSize={20} marginTop={5}>
						Rising Sun Foundation (RSF) is a registered Non-Profit Organisation (NPO)
						and Public Benefit Organisation (PBO) that conceptualises and implements
						musical talent development programs for youth groups, thereby enabling them
						to be inspired to participate and explore not only the platform for
						performance, but also opportunities for sustainable career choices in order
						for them to make a living out of music and art.
					</Typography>
					<Typography variant='subtitle1' fontSize={20}>
						This is achieved by identifying suitably gifted and motivated youth and
						offer them musical training through expert support in music production,
						vocal coaching, intensive rehearsals and building self-confidence.
					</Typography>
				</Box>
				<Box width='100%' marginTop={50}>
					<img src='members.png' alt='members' width='100%' />
				</Box>
				<Box sx={{ color: '#F5F1E8', p: 5 }}>
					<Typography
						variant='subtitle1'
						fontWeight={800}
						fontSize={24}
						textTransform='uppercase'
					>
						our mission
					</Typography>
					<Grid container spacing={5}>
						<Grid item xs={12} md={6}>
							<Typography variant='subtitle1' fontSize={20}>
								Rising Sun Foundation’s mission is to provide musical talent
								development programs to youth groups, thereby enabling them to be
								inspired to participate and explore not only the platform for
								performance, but also opportunities for sustainable career choices
								in order for them to make a living out of music and art.
							</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography variant='subtitle1' fontSize={18}>
								This is achieved by identifying suitably gifted and motivated youth
								and offer them musical training through expert support in music
								production, vocal coaching, intensive rehearsals and building
								self-confidence. Different genres in standard categories
								respectively will compete for regional honours and a chance to
								compete at the provincial finals in what promises to be an intense
								competition between the province’s top vocal talents and performers.
								The winner will receive a recording contract and also record a live
								DVD, musical equipment and prize money to kick-start their careers,
								as the mission is to enrich the musical life of Mpumalanga Province.
							</Typography>
						</Grid>
					</Grid>
					<Box
						marginTop={5}
						// width={'100%'}
						position={'absolute'}
						left={0}
						right={0}
						padding={5}
						sx={{
							background:
								"url('bg2.png') 100% 25%, url('bg3.png') 65% 40%, url('bg1.png') right bottom, white",
							backgroundSize: '150px, 150px, 200px',
							backgroundRepeat: 'no-repeat',
							// backgroundPosition: 'right',
							color: 'rgba(0, 0, 0, 0.8)',
						}}
					>
						<Typography
							variant='subtitle1'
							fontWeight={800}
							fontSize={24}
							textTransform='uppercase'
						>
							aims and objectives
						</Typography>
						<Grid container spacing={5}>
							<Grid item xs={12} md={6}>
								<List dense>
									<ListItem>
										<ListItemText>
											<Typography variant='subtitle1' fontSize={24}>
												01 Unearth or identify talent and nurture talent;
											</Typography>
										</ListItemText>
									</ListItem>
									<ListItem>
										<ListItemText>
											<Typography variant='subtitle1' fontSize={24}>
												02 Advance the development, education and
												preservation of musical talents and culture;
											</Typography>
										</ListItemText>
									</ListItem>
									<ListItem>
										<ListItemText>
											<Typography variant='subtitle1' fontSize={24}>
												03 Promote and ensure integrated youth and community
												musical activities – choirs, traditional dances,
												poetry and all genres of music such as mbaqanga, the
												newly formed genre Amapiano, ensembles groups and so
											</Typography>
										</ListItemText>
									</ListItem>
									<ListItem>
										<ListItemText>
											<Typography variant='subtitle1' fontSize={24}>
												04 Unite the different cultures in Mpumalanga
												Province through music;
											</Typography>
										</ListItemText>
									</ListItem>
									<ListItem>
										<ListItemText>
											<Typography variant='subtitle1' fontSize={24}>
												05 Enrich the lives young people and people with
												disabilities from previously disadvantaged
												backgrounds through music education, performance and
												collaboration;
											</Typography>
										</ListItemText>
									</ListItem>
								</List>
							</Grid>
							<Grid item xs={12} md={6}>
								<List dense>
									<ListItem>
										<ListItemText>
											<Typography variant='subtitle1' fontSize={24}>
												06 Contribute to a sense of pride in communities,
												through the sharing and enjoyment of musical
												activities;
											</Typography>
										</ListItemText>
									</ListItem>
									<ListItem>
										<ListItemText>
											<Typography variant='subtitle1' fontSize={24}>
												07 Provide a nurturing environment and platform in
												which the community can showcase its musical
												talents;
											</Typography>
										</ListItemText>
									</ListItem>
									<ListItem>
										<ListItemText>
											<Typography variant='subtitle1' fontSize={24}>
												08 Raise funds and conduct any business necessary
												for these purposes; Raise funds and conduct any
												business necessary for these purposes; Encourage
												co-ordination and co-operation among organisations
												and institutions with similar aims; and
											</Typography>
										</ListItemText>
									</ListItem>
									<ListItem>
										<ListItemText>
											<Typography variant='subtitle1' fontSize={24}>
												09 Use music as an instrument for development and
												poverty alleviation
											</Typography>
										</ListItemText>
									</ListItem>
								</List>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Box>
		</ThemeProvider>
	);
};

export default About;
