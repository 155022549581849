import { ArrowBack } from '@mui/icons-material';
import {
	AppBar,
	Avatar,
	Box,
	createTheme,
	IconButton,
	ThemeProvider,
	Toolbar,
	Typography,
} from '@mui/material';
import React from 'react';
import ReactPlayer from 'react-player';
import Footer from '../components/Footer';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const theme = createTheme({
	palette: {
		primary: {
			main: '#F5F1E8',
		},
		secondary: {
			main: '#F49C34',
		},
	},
});

const vlogs = [
	{
		author: 'Lesi Nxumalo',
		date: new Date('2022-08-27'),
		thumbnail: '../testimonials/vlog7.jpeg',
		summary:
			'9th Ingoma Gospel Music Awards: Best Live Recorded DVD Award Recipient - Rising Sun (The Rising Glory Album)',
		title: 'Best Live Recorded DVD',
		src: '../testimonials/vlog7.mp4',
		color: '#C50260',
	},
	{
		author: 'Lesi Nxumalo',
		date: new Date('2022-08-27'),
		thumbnail: '../testimonials/vlog6.jpeg',
		summary:
			'9th Ingoma Gospel Music Awards: Best Gospel Collaboration Award Recipient - Rising Sun FT Ayanda Ntanzi',
		title: 'Best Gospel Collaboration',
		src: '../testimonials/vlog6.mp4',
		color: '#A60760',
	},
	{
		author: 'Lesi Nxumalo',
		date: new Date('2022-08-27'),
		thumbnail: '../testimonials/vlog5.jpeg',
		summary: '9th Ingoma Gospel Music Awards: Trailblazer Award Recipient - Smanga Singwane',
		title: 'Trailblazer Award',
		src: '../testimonials/vlog5.mp4',
		color: '#FEBD09',
	},
	{
		author: 'Lesi Nxumalo',
		date: new Date('2022-07-18'),
		thumbnail: '../testimonials/vlog3.jpeg',
		summary: '67 Minutes with Rising Sun',
		title: 'Mandela International Day',
		src: '../testimonials/vlog3.mp4',
		color: '#DF7E29',
	},
	{
		author: 'Lesi Nxumalo',
		// date: new Date('2021-11-20'),
		thumbnail: '../testimonials/vlog4.jpeg',
		summary: '',
		title: 'Visit to Dr. Mahlangu',
		src: '../testimonials/busVid2.mp4',
		color: 'blue',
	},
	{
		author: 'Lesi Nxumalo',
		// date: new Date('2021-11-20'),
		thumbnail: '../testimonials/vlog1.jpeg',
		summary: '',
		title: 'Gospel Classic Shoot',
		src: '../testimonials/vlog1.mp4',
		color: 'orange',
	},
	{
		author: 'Lesi Nxumalo',
		// date: new Date('2021-11-20'),
		thumbnail: '../testimonials/vlog2.jpeg',
		summary: '',
		title: 'Rising Sun choir buses',
		src: '../testimonials/busVid1.mp4',
		color: 'purple',
	},
];

const Blog = () => {
	return (
		<ThemeProvider theme={theme}>
			<Helmet>
				<link rel='icon' href='rising_sun3.ico' />
			</Helmet>
			<Box
				sx={{
					left: 0,
					right: 0,
					top: 0,
					zIndex: 0,
					position: 'absolute',
					background: "url('../untitled-196.jpg')",
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
				}}
			>
				<Box
					width='100%'
					height='100%'
					zIndex={1}
					sx={{
						backgroundColor: 'rgba(0,0,0,0.5)',
					}}
				>
					<AppBar
						position='relative'
						color='transparent'
						sx={{ zIndex: 2, boxShadow: 0 }}
					>
						<Toolbar>
							<Link to='/'>
								<IconButton
									size='large'
									edge='start'
									aria-label='home'
									// href='/'
									sx={{ mr: 2, color: '#F49C34' }}
								>
									<ArrowBack />
								</IconButton>
							</Link>
							<Link to='/'>
								<Avatar
									src='../rising_sun2.png'
									alt='Rising Sun Foundation Logo'
									variant='rounded'
									sx={{
										width: '200px',
										height: '100%',
									}}
								/>
							</Link>
						</Toolbar>
					</AppBar>
					<Box
						display='flex'
						justifyContent='center'
						alignItems='center'
						width='100%'
						marginTop='20px'
					>
						<Typography
							variant='subtitle1'
							sx={{
								textTransform: 'uppercase',
								fontWeight: 800,
								fontSize: '20pt',
							}}
							color='secondary'
						>
							Blog
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box marginTop={25} left={0} right={0}>
				{vlogs.map(({ title, author, thumbnail, src, color, summary, date }) => (
					<Box
						sx={{
							flexDirection: { xs: 'column', lg: 'row' },
							width: '100%',
							display: 'flex',
							justifyContent: 'space-around',
							alignContent: 'center',
							my: { xs: 2, lg: 5 },
							p: { lg: 2 },
						}}
					>
						<Box
							sx={{
								width: { lg: '30%' },
								height: '250px',
								display: 'flex',
								alignItems: 'center',
								boxShadow: `2px 2px 20px ${color}`,
							}}
						>
							<ReactPlayer
								controls
								url={src}
								width='100%'
								height='100%'
								light={thumbnail}
							/>
						</Box>
						<Box
							sx={{
								margin: { xs: '20px 0 0 0', lg: '0 0 0 20px' },
								width: { lg: '65%' },
							}}
						>
							<Box key={title}>
								<Typography variant='h6' fontWeight={800} sx={{ color: color }}>
									{author}
								</Typography>
								<Typography variant='h3' textTransform={'capitalize'}>
									{title}
								</Typography>
								<Typography variant='subtitle1'>{summary}</Typography>

								{date !== undefined ? (
									<Typography
										variant='subtitle2'
										fontStyle='italic'
										fontWeight={700}
									>
										{date.getDate()}{' '}
										{date.toLocaleString('en-us', { month: 'long' })}{' '}
										{date.getFullYear()}
									</Typography>
								) : (
									<Typography></Typography>
								)}
							</Box>
						</Box>
					</Box>
				))}
				<Footer />
			</Box>
		</ThemeProvider>
	);
};

export default Blog;
